// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "./bootstrap/scss/functions";

// 2. Include any default variable overrides here
$primary: rgb(229, 42, 5);
$secondary: rgb(246, 129, 0);
$success: rgb(40, 167, 69);
$light: rgb(233, 236, 239);

$enable-negative-margins: true;

// 3. Include remainder of required Bootstrap stylesheets
@import "./bootstrap/scss/variables";
// @import "./bootstrap/scss/mixins";
// @import "./bootstrap/scss/root";

$primary-light: tint-color($primary, 90%);
$secondary-light: tint-color($secondary, 90%);
$success-light: tint-color($success, 90%);
$danger-light: tint-color($danger, 90%);
$light-light: tint-color($light, 75%);

$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.75;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base * 1.1;
$h6-font-size: $font-size-base;

.btn-close {
  &:focus {
    box-shadow: none !important;
  }
}

// $font-sizes: (
//   1: $h1-font-size,
//   2: $h2-font-size,
//   3: $h3-font-size,
//   4: $h4-font-size,
//   5: $h5-font-size,
//   6: $h6-font-size
// );

$input-btn-focus-box-shadow: none;
$btn-box-shadow: none;
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;
$input-box-shadow: none;
$input-focus-box-shadow: none;
$form-select-box-shadow: none;
$form-select-focus-box-shadow: none;
$form-check-input-focus-box-shadow: none;

// Additional theme colors
$custom-colors: (
  "midgray": $gray-400,
  "gray": $gray-600,
);
$colors: map-merge($colors, $custom-colors);
$theme-colors: map-merge($theme-colors, $custom-colors);
$utilities-text-colors: map-merge($utilities-text-colors, $custom-colors);
$utilities-bg-colors: map-merge($utilities-bg-colors, $custom-colors);

// Additional sizes
$custom-spacers: (
  6: $spacer * 4.5,
  7: $spacer * 6,
  8: $spacer * 7.5,
);
$spacers: map-merge($spacers, $custom-spacers);

@import "./bootstrap/scss/mixins";
@import "./bootstrap/scss/root";

// Include utilities
@import "./bootstrap/scss/utilities";

// Additional width sizes
$utilities: map-merge(
  $utilities,
  (
    "width": map-merge(
      map-get($utilities, "width"),
      (
        values: map-merge(
          map-get(map-get($utilities, "width"), "values"),
          (10: 10%,
          20: 20%,
          30: 30%,
          40: 40%,
          60: 60%,
          70: 70%,
          80: 80%,
          90: 90%),
        ),
      ),
    ),
  )
);

// Responsive height/width classes
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $prop, $abbrev in (width: w, height: h) {
      @each $size, $length in map-get(map-get($utilities, "width"), "values") {
        .#{$abbrev}#{$infix}-#{$size} {
          #{$prop}: $length !important;
        }
      }
    }
  }
}

// Include optional components

@import "./bootstrap/scss/reboot";
@import "./bootstrap/scss/type";
@import "./bootstrap/scss/images";
@import "./bootstrap/scss/containers";
@import "./bootstrap/scss/grid";
@import "./bootstrap/scss/tables";
@import "./bootstrap/scss/forms";
@import "./bootstrap/scss/buttons";
@import "./bootstrap/scss/transitions";
@import "./bootstrap/scss/dropdown";
@import "./bootstrap/scss/button-group";
@import "./bootstrap/scss/nav";
@import "./bootstrap/scss/navbar";
@import "./bootstrap/scss/card";
@import "./bootstrap/scss/accordion";
@import "./bootstrap/scss/breadcrumb";
@import "./bootstrap/scss/pagination";
@import "./bootstrap/scss/badge";
@import "./bootstrap/scss/alert";
@import "./bootstrap/scss/progress";
@import "./bootstrap/scss/list-group";
@import "./bootstrap/scss/close";
@import "./bootstrap/scss/toasts";
@import "./bootstrap/scss/modal";
@import "./bootstrap/scss/tooltip";
@import "./bootstrap/scss/popover";
@import "./bootstrap/scss/carousel";
@import "./bootstrap/scss/spinners";
@import "./bootstrap/scss/offcanvas";
@import "./bootstrap/scss/helpers";

// 5. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "./bootstrap/scss/utilities/api";

// 6. Add additional custom code here
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap");

.fontBody {
  /* font-family: "Poppins", sans-serif; */
  /* font-family: "Lato", sans-serif; */
  font-family: "Roboto", sans-serif;
  /* font-family: "Quicksand", sans-serif; */
  /* font-family: "Questrial", sans-serif; */
  font-weight: 300;
}

.fontHeaders {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
}

body {
  @extend .fontBody;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  @extend .fontHeaders;
}

h1 {
  font-size: $h3-font-size;
  font-weight: bold;
}

// Light bg colors
.bg-primary-light {
  background: $primary-light;
}

.bg-secondary-light {
  background: $secondary-light;
}

.bg-light-light {
  background: $light-light;
}

.bg-success-light {
  background: $success-light;
}

.bg-danger-light {
  background: $danger-light;
}

// Buttons - success
.btn-success {
  color: #fff;
  background-color: rgb(40, 167, 69);
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

// .btn-success:focus,
// .btn-success.focus {
//   box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
// }

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

// .btn-success:not(:disabled):not(.disabled):active:focus,
// .btn-success:not(:disabled):not(.disabled).active:focus,
// .show > .btn-success.dropdown-toggle:focus {
//   box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
// }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

// .btn-outline-success:focus,
// .btn-outline-success.focus {
//   box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
// }

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

// .btn-outline-success:not(:disabled):not(.disabled):active:focus,
// .btn-outline-success:not(:disabled):not(.disabled).active:focus,
// .show > .btn-outline-success.dropdown-toggle:focus {
//   box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
// }

.accordion-button:focus,
.form-control:focus {
  box-shadow: none;
}

.accordion-button {
  font-size: inherit !important;
}

a {
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.z-9 {
  z-index: 9;
}

.z-99 {
  z-index: 99;
}

.opacity-1 {
  opacity: 1;
}

.opacity-0 {
  opacity: 0;
}

// .bgHover-[color] classes
@each $color, $value in $theme-colors {
  .bgHover-#{$color}:hover {
    background-color: $value !important;
  }
}

// .textHover-[color] classes
@each $color, $value in $theme-colors {
  .textHover-#{$color}:hover {
    color: $value !important;
  }
}

// Transition
@for $i from 1 through 10 {
  .trans-#{$i} {
    transition: all #{$i}#{"00"}ms;
  }
}

// Position
.posAbs {
  position: absolute;
}

.posRelative {
  position: relative;
}

.posFixed {
  position: fixed;
}

.posSticky {
  position: sticky;
}

.posTop {
  top: 0;
}

.posBottom {
  bottom: 0;
}

.posLeft {
  left: 0;
}

.posRight {
  right: 0;
}

.cursorPointer {
  cursor: pointer;
}

/* Font sizes */

.fontSize0 {
  font-size: 0;
}

.fontSize07 {
  font-size: 0.7rem;
}

.fontSize08 {
  font-size: 0.8rem;
}

.fontSize09 {
  font-size: 0.9rem;
}

.fontSize11 {
  font-size: 1.1rem;
}

.fontSize12 {
  font-size: 1.2rem;
}

.fontSize15 {
  font-size: 1.5rem;
}

.fontSize20 {
  font-size: 2rem;
}

.fontSize25 {
  font-size: 2.5rem;
}

.overflowHidden {
  overflow: hidden;
}

// Text styles
.text-bold {
  font-weight: bold;
}

.text-italic {
  font-style: italic;
}

.text-underline {
  text-decoration: underline;
}

.text-strike {
  text-decoration: line-through;
}

.text-super,
.text-sub {
  font-size: 0.83em;
}

.text-super {
  vertical-align: super;
}

.text-sub {
  vertical-align: sub;
}

// Satonda colors
.btn-satonda {
  @extend .btn;
  border-radius: 1000px;
  padding: 0.6rem 2.5rem;
  color: $primary;
  // border: double 3px white;
  background: linear-gradient(135deg, #f68100 0%, #e52a05 75%);
  background-origin: border-box;
  box-shadow: 0px 1000px 0px #fff inset, 0px 3px 7px 0px rgba(0, 0, 0, 0);
  transition: all 0.3s ease-in-out;
}

.btn-satonda:hover {
  box-shadow: none;
  // box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0);
  color: white;
}

.text-satonda {
  // font-stretch: ultra-condensed;
  box-decoration-break: clone;
  display: inline;
  background: linear-gradient(225deg, #f68100 0%, #e52a05 75%);
  background: -webkit-gradient(225deg, #f68100 0%, #e52a05 75%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-satonda {
  background: linear-gradient(225deg, #f68100 0%, #e52a05 75%);
  background: -webkit-gradient(225deg, #f68100 0%, #e52a05 75%);
}

.icon-satonda svg {
  fill: url(#icon-gradient-satonda) #e52a05;
}

.flexSameHeight {
  display: flex;
  flex: 1 0 0%;
  flex-direction: column;
  height: 100%;
}

.flexSameWidth {
  display: flex;
  flex: 1 0 0%;
  flex-direction: row;
  width: 100%;
}

.toGrow {
  flex: 1 1 auto;
}

.shadow-light {
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
}

.shadow-light:hover:not(.noHover) {
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.2);
}

.shadow-medium {
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
}

.shadow-medium:hover:not(.noHover) {
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.3);
}

.shadow-wide {
  box-shadow: 0px 0px 30px rgba(73, 78, 92, 0.15);
}

.shadow-wide:hover:not(.noHover) {
  box-shadow: 0px 0px 30px rgba(73, 78, 92, 0.25);
}

.rounded-xl {
  border-radius: 0.625rem;
}

.rounded-xxl {
  border-radius: 1rem;
}

/* CSS only switch */
.switchWrapper {
  width: 3rem;
  height: 1.5rem;
  cursor: pointer;
  background: #dfdfdf;
  border-radius: 2em;
  padding: 2px;
  transition: all 0.3s ease;
}

.switchBall {
  position: relative;
  display: block;
  width: 50%;
  height: 100%;
  left: 0;
  border-radius: 50%;
  background: #fff;
  transition: all 0.3s ease;
}

.switchWrapper.checked {
  background: $primary;
  transition: all 0.3s ease;
}

.switchWrapper.checked.switchWrapper-success {
  background: $success !important;
  transition: all 0.3s ease;
}

.switchWrapper.checked .switchBall {
  left: 50%;
  transition: all 0.3s ease;
}

// Custom togglers :: https://codepen.io/mallendeo/pen/eLIiG
.tgl {
  display: none;
  &,
  &:after,
  &:before,
  & *,
  & *:after,
  & *:before,
  & + .tgl-btn {
    box-sizing: border-box;
    &::selection {
      background: none;
    }
  }

  + .tgl-btn {
    outline: 0;
    display: block;
    width: 6em;
    height: 2em;
    position: relative;
    cursor: pointer;
    user-select: none;
    &:after,
    &:before {
      position: relative;
      display: block;
      content: "";
      width: 50%;
      height: 100%;
    }

    &:after {
      left: 0;
    }

    &:before {
      display: none;
    }
  }

  &:checked + .tgl-btn:after {
    left: 50%;
  }
}

// Variations
.tgl-light {
  + .tgl-btn {
    background: #f0f0f0;
    border-radius: 2em;
    padding: 2px;
    transition: all 0.4s ease;
    &:after {
      border-radius: 50%;
      background: #fff;
      transition: all 0.2s ease;
    }
  }

  &:checked + .tgl-btn {
    background: #9fd6ae;
  }
}

.tgl-ios {
  + .tgl-btn {
    background: #fbfbfb;
    border-radius: 2em;
    padding: 2px;
    transition: all 0.4s ease;
    border: 1px solid #e8eae9;
    &:after {
      border-radius: 2em;
      background: #fbfbfb;
      transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 rgba(0, 0, 0, 0.08);
    }

    &:hover:after {
      will-change: padding;
    }

    &:active {
      box-shadow: inset 0 0 0 2em #e8eae9;
      &:after {
        padding-right: 0.8em;
      }
    }
  }

  &:checked + .tgl-btn {
    background: #86d993;
    &:active {
      box-shadow: none;
      &:after {
        margin-left: -0.8em;
      }
    }
  }
}

.tgl-skewed {
  + .tgl-btn {
    overflow: hidden;
    transform: skew(-10deg);
    backface-visibility: hidden;
    transition: all 0.2s ease;
    font-family: sans-serif;
    background: #888;
    &:after,
    &:before {
      transform: skew(10deg);
      display: inline-block;
      transition: all 0.2s ease;
      width: 100%;
      text-align: center;
      position: absolute;
      line-height: 2em;
      font-weight: bold;
      color: #fff;
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
    }

    &:after {
      left: 100%;
      content: attr(data-tg-on);
    }

    &:before {
      left: 0;
      content: attr(data-tg-off);
    }

    &:active {
      background: #888;
      &:before {
        left: -10%;
      }
    }
  }

  &:checked + .tgl-btn {
    background: #86d993;
    &:before {
      left: -100%;
    }

    &:after {
      left: 0;
    }

    &:active:after {
      left: 10%;
    }
  }
}

.tgl-flat {
  + .tgl-btn {
    padding: 2px;
    transition: all 0.2s ease;
    background: #fff;
    border: 4px solid #f2f2f2;
    border-radius: 2em;
    &:after {
      transition: all 0.2s ease;
      background: #f2f2f2;
      content: "";
      border-radius: 1em;
    }
  }

  &:checked + .tgl-btn {
    border: 4px solid #7fc6a6;
    &:after {
      left: 50%;
      background: #7fc6a6;
    }
  }
}

.tgl-flip {
  + .tgl-btn {
    padding: 2px;
    transition: all 0.2s ease;
    // font-family: sans-serif;
    @extend .fontBody;
    perspective: 100px;
    &:after,
    &:before {
      display: inline-block;
      transition: all 0.4s ease;
      width: 100%;
      text-align: center;
      position: absolute;
      line-height: 2em;
      font-weight: bold;
      color: #fff;
      position: absolute;
      top: 0;
      left: 0;
      backface-visibility: hidden;
      border-radius: 6px;
    }

    &:after {
      content: attr(data-tg-on);
      // background: #02c66f;
      background: $success;
      transform: rotateY(-180deg);
    }

    &:before {
      // background: #ff3a19;
      background: $primary;
      content: attr(data-tg-off);
    }

    &:active:before {
      transform: rotateY(-20deg);
    }
  }

  &:checked + .tgl-btn {
    &:before {
      transform: rotateY(180deg);
    }

    &:after {
      transform: rotateY(0);
      left: 0;
      // background: #7fc6a6;
      background: $success;
    }

    &:active:after {
      transform: rotateY(20deg);
    }
  }
}

.backToTop {
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 1rem;
  // border-radius: 6px;
  right: 1rem;
  bottom: 1rem;
  @extend .trans-3;
  cursor: pointer;
  display: none;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;  
  border-radius: 50%;
  box-shadow: 0px 0px 6px 2px rgba(0,0,0,0);

  &:hover {
    background: $primary;
    // @extend .bg-satonda;
    color: #fff;
    box-shadow: 0px 0px 6px 2px rgba(0,0,0,0.25);
  }
  &>i {
    font-size: 1rem;
  }
  &.shown {
    display: flex;
  }
}

// Sidebar
.btnToggleSidebar {
  color: $dark;
  font-size: 2rem;  
  @extend .trans-3;
  cursor: pointer;
  align-items: center;
  justify-content: start;
  margin-bottom: 1rem;

  &:hover {
    color: $gray-600;
  }
}

.btn-toggle {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.65);
  // color: $primary;
  background-color: transparent;
  border: 0;
}
.btn-toggle:hover,
.btn-toggle:focus {
  color: rgba(0, 0, 0, 0.85);
  // color: $primary;
  // background-color: $primary-light;
  background-color: #d2d2d2;
}

.btn-toggle::before {
  width: 1.25em;
  line-height: 0;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
  transition: transform 0.35s ease;
  transform-origin: 0.5em 50%;
}

.btn-toggle[aria-expanded="true"] {
  color: rgba(0, 0, 0, 0.85);
  // color: $primary;
}
.btn-toggle[aria-expanded="true"]::before {
  transform: rotate(90deg);
}

.btn-toggle-nav a {
  display: inline-flex;
  padding: 0.1875rem 0.5rem;
  margin-top: 0.125rem;
  margin-left: 1.25rem;
  text-decoration: none;
}
.btn-toggle-nav a:hover,
.btn-toggle-nav a:focus {
  // background-color: $primary-light;
  background-color: #d2d2d2;
}

.sidebar-shadow {
  box-shadow: 3px 0px 3px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 3px 0px 3px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 3px 0px 3px 0px rgba(0, 0, 0, 0.2);
}

// Navbar icons
.navbar-icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  cursor: pointer;
  border-radius: 50%;
  color: $primary;
  font-size: 1.5rem;
  transition: 0.3s all;
}

.navbarNumBadge {
  position: absolute;
  right: 0;
  bottom: -4px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: $light;
}

.navbarNumBadge span {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  font-size: 50%;
  color: $primary;
  transition: 0.3s;
}

li:hover .navbarNumBadge span {
  background-color: rgba(255, 255, 255, 0.75);
}

.col-icon {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 2.5rem;
  font-size: 2.5rem;
  margin-bottom: map-get($map: $spacers, $key: 2);
}

.detail-icon {
  font-size: $spacer * 6;
}

/* Product table */

.productTable_product {
  border: 1px solid $gray-600;
  border-radius: 0.8rem;
  transition: all 0.3s ease;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.2);
}

.productTable_product:hover {
  border-color: $success;
  box-shadow: 0px 2px 12px rgba(40, 167, 69, 0.2);
  -webkit-box-shadow: 0px 2px 12px rgba(40, 167, 69, 0.2);
  -moz-box-shadow: 0px 2px 12px rgba(40, 167, 69, 0.2);
}

.productTable_header {
  border-radius: 0.8rem;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.2);
  color: $light-light;
  background: $primary;
}

.productTable_specs {
  padding: 0;
  list-style: none;
}

.productTable_specs li::before {
  content: "\2714\0020";
  color: $success;
  font-weight: bold;
  display: inline-block;
  width: 1rem;
  margin-left: 0;
  margin-right: 0.4rem;
}

.productPricing {
  color: $primary;
}

.productPricing .productPrice {
  font-weight: bold;
  font-size: larger;
}

.productPricing .pricePerPeriod {
  vertical-align: sub;
  font-size: smaller;
  font-style: italic;
}

// Rounded pill input group
.input-group.rounded-pill {
  .form-control {
    border-radius: 50rem 0 0 50rem !important;
  }
  button {
    border-radius: 0 50rem 50rem 0 !important;
  }
}

// Domain checker
.domainChecker-item.available {
  cursor: pointer;
}

.domainChecker-item {
  border: solid 1px $light;
  transition: all 0.3s ease;
}

.domainChecker-item:hover.available,.domainChecker-item.inCart {
  border-color: $success !important;
}

.domainChecker-item:hover.taken {
  border-color: $danger !important;
}

.domainChecker-item.inCart {
  color: $success !important;  
}

.domainChecker-item {
  .domainChecker-iconFlip {
    opacity: 1;
    position: relative;
    text-align: center;
    display: inline-block;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;

    &:after {
      // top: 0;
      top: -24px;
      left: 0;
      // opacity: 0;
      width: 100%;
      display: block;
      transition: 0.3s;
      position: absolute;
      content: "\f07a";
      // transform: translateY(-50%) rotateX(90deg);
      transform: translateY(-50%);
    }

    &:before {
      top: 0;
      left: 0;
      // opacity: 1;
      display: block;
      transition: 0.3s;
      position: relative;
      content: "\f00c";
      // transform: translateY(0) rotateX(0);
      transform: translateY(0);
    }
  }

  &:hover {
    .domainChecker-iconFlip {
      &:after {
        top: 0;
        // opacity: 1;
        // transform: translateY(0) rotateX(0);
        transform: translateY(0);
      }

      &:before {
        top: 24px;
        // opacity: 0;
        // transform: translateY(50%) rotateX(90deg);
        transform: translateY(50%);
      }
    }
  }
}

// Input box callout shadow
.input-callout-primary {
  box-shadow: 0px 0px 15px 5px rgba($primary, 0.5) !important;
}

.input-callout-light {
  // box-shadow: 0px 0px 15px 5px rgba($primary, 0.5);
  box-shadow: 0px 0px 15px 5px rgba(206, 206, 206, 0.5);
}

// All domains - category
.allDomainsCat {
  cursor: pointer;
  transition: all 0.3s ease;
  // @extend .rounded-3;
  background: $light-light;
  border-bottom: 1px solid $light;
}

.allDomainsCat:first-of-type {
  border-top: 1px solid $light;
}

.allDomainsCat:hover,
.allDomainsCat.selected {
  background: $primary-light;
  color: $primary;
}

// All domains - TLD option
.allTldsItem {
  border-right: 1px solid $light;
  border-bottom: 1px solid $light;
}

// Steps circle
.stepCircle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  @extend .bg-satonda;
  @extend .h1;
  margin: 0 !important;
  color: #fff;
  box-shadow: 0px 0px 15px 5px rgba(180, 180, 180, 0.5);
}

// File manager
.fm-nav {
  background-color: inherit;
  padding: 0.5rem;
  transition: 0.3s all;
  cursor: pointer;
}

.fm-nav:hover {
  background-color: #dddddd;
}

.fm-actionBar {
  background-color: $primary-light;
}

.fm-actionBar-new {
  box-shadow: 5px 5px 6px 0px rgba(0,0,0,0.25);
-webkit-box-shadow: 5px 5px 6px 0px rgba(0,0,0,0.25);
-moz-box-shadow: 5px 5px 6px 0px rgba(0,0,0,0.25);
}

.fm-actionBar-new span {
  background-color: #f6fcff;
  cursor: pointer;
  transition: 0.3s all;
}

.fm-actionBar-new span:hover {
  // background-color: #d6e7f0;
  background-color: tint-color($primary, 65%);
}

.fm-action {
  background-color: inherit;
  padding: 0.4rem;
  transition: 0.3s all;
}

.fm-action:not(.fm-noHover):hover {
  cursor: pointer;
  // background-color: #a4becc;
  background-color: tint-color($primary, 75%);
}

.fm-action:not(.fm-noHover).fm-active {
  cursor: pointer;
  // background-color: #a4becc;
  background-color: tint-color($primary, 75%);
}

.fm-listItem {
  background-color: #f8f9fa;
  transition: 0.3s all;
}

.fm-listItem:hover {
  background-color: #dddddd;
}

.fm-itemName {
  transition: 0.3s all;
  cursor: pointer;
}

.fm-itemName:hover {
  text-decoration: underline;
}

.fm-itemActions {
  position: relative;
}

.fm-listItem .fm-itemActions span {
  position: absolute;
  left: 0;
  top: calc(50% - 12px);
  opacity: 0;
  cursor: pointer;
  transition: 0.3s all;
}

.fm-listItem:hover .fm-itemActions span {
  opacity: 1;
}

.fm-listItem:hover .fm-itemActions span:nth-child(1) {
  left: 0 !important;
}

.fm-listItem:hover .fm-itemActions span:nth-child(2) {
  left: 24px !important;
}

.fm-listItem:hover .fm-itemActions span:nth-child(3) {
  left: 48px !important;
}

.fm-listItem:hover .fm-itemActions span:nth-child(4) {
  left: 72px !important;
}

// Round checkbox (used in filemanager)
.roundCb {
  display: inline-block;
  height: 19px;
  width: 19px;
  border-radius: 50%;
  border: 1px solid #ccc;
  cursor: pointer;
  transition: all 0.1s;  
  background-repeat: no-repeat;
  background-size: 0% 0%;
  background-position: 50% 50%;
}

.roundCb-checked {
  background-size: 100% 100%;  
}

.roundCb-primary {
  background-image: linear-gradient($primary, $primary);
  background-image: -webkit-linear-gradient($primary, $primary);
  background-image: -moz-linear-gradient($primary, $primary);
}

.roundCb-checked.roundCb-primary {
  border-color: $primary;
}

.roundCb-secondary {
  background-image: linear-gradient($secondary, $secondary);
  background-image: -webkit-linear-gradient($secondary, $secondary);
  background-image: -moz-linear-gradient($secondary, $secondary);
}

.roundCb-checked.roundCb-secondary {
  border-color: $secondary;
}

.roundCb-info {
  background-image: linear-gradient($info, $info);
  background-image: -webkit-linear-gradient($info, $info);
  background-image: -moz-linear-gradient($info, $info);
}

.roundCb-checked.roundCb-info {
  border-color: $info;
}

.roundCb-success {
  background-image: linear-gradient($success, $success);
  background-image: -webkit-linear-gradient($success, $success);
  background-image: -moz-linear-gradient($success, $success);
}

.roundCb-checked.roundCb-success {
  border-color: $success;
}

.roundCb-warning {
  background-image: linear-gradient($warning, $warning);
  background-image: -webkit-linear-gradient($warning, $warning);
  background-image: -moz-linear-gradient($warning, $warning);
}

.roundCb-checked.roundCb-warning {
  border-color: $warning;
}

.roundCb-danger {
  background-image: linear-gradient($danger, $danger);
  background-image: -webkit-linear-gradient($danger, $danger);
  background-image: -moz-linear-gradient($danger, $danger);
}

.roundCb-checked.roundCb-danger {
  border-color: $danger;
}

.roundCb-light {
  background-image: linear-gradient($light, $light);
  background-image: -webkit-linear-gradient($light, $light);
  background-image: -moz-linear-gradient($light, $light);
}

.roundCb-checked.roundCb-light {
  border-color: $light;
}

.roundCb-dark {
  background-image: linear-gradient($dark, $dark);
  background-image: -webkit-linear-gradient($dark, $dark);
  background-image: -moz-linear-gradient($dark, $dark);
}

.roundCb-checked.roundCb-dark {
  border-color: $dark;
}

/* Pulse check */

.pulseCheck {
  position: absolute;
  left: 50%;
  top: 15px;
  z-index: 201;
  transform: translateX(-50%);
  transition: all 0.3s ease;
  background-color: rgba(230, 230, 230, 0.75);
  padding: 0.3rem 1.25rem;
  border-radius: 8px;
  box-shadow: 0 0 6px rgb(100, 100, 100);
}

.pulseCheck-dismiss {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(25%, -25%);
  border-radius: 50%;
  border: solid 1px rgb(108, 117, 125);
  background-color: #fff;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  color: rgb(220, 56, 72);
  cursor: pointer;
}

.pulseCheck p {
  font-size: .8rem;
  margin-bottom: 0.5rem;
}

.pulseCheck-score {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: calc(1.4rem * 21.7 / 22.4 * 0.9);
  width: calc(1.4rem * 0.9);
  background-color: #fff;
  border-radius: 50%;
  font-size: 1.4rem;
  cursor: pointer;
  transition: all 0.3s ease;  
}

.pulseCheck-score:nth-child(1) {
  color: rgb(220, 56, 72);
}

.pulseCheck-score:nth-child(2) {
  color: rgb(255, 193, 7);
}

.pulseCheck-score:nth-child(3) {
  color: rgb(95, 176, 114);
}

.pulseCheck-score:nth-child(4) {
  color: rgb(40, 167, 69);
}

.pulseCheck-score:nth-child(1):hover {
  filter: drop-shadow(0 0 4px rgb(220, 56, 72));
}

.pulseCheck-score:nth-child(2):hover {
  filter: drop-shadow(0 0 4px rgb(255, 193, 7));
}

.pulseCheck-score:nth-child(3):hover {
  filter: drop-shadow(0 0 4px rgb(95, 176, 114));
}

.pulseCheck-score:nth-child(4):hover {
  filter: drop-shadow(0 0 4px rgb(40, 167, 69));
}

.pulseCheck-score:not(:last-child) {
  margin-right: 0.8rem;
}

.pulseCheck-thanks {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

// Remove thick black border from tables
.table > :not(:first-child) {
  border-top: 0;
}

// Notifications
.unreadNotification {
  background: $primary-light;
  font-weight: bolder;
}

// Stripe payment element
#payment-form {
  // width: 30vw;
  // min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}

#payment-element {
  margin-bottom: 24px;
}

/* spinner/processing state, errors */
#stripe-spinner,
#stripe-spinner:before,
#stripe-spinner:after {
  border-radius: 50%;
}

#stripe-spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

#stripe-spinner:before,
#stripe-spinner:after {
  position: absolute;
  content: '';
}

#stripe-spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: $primary;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: stripe-loading 2s infinite ease 1.5s;
  animation: stripe-loading 2s infinite ease 1.5s;
}

#stripe-spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: $primary;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: stripe-loading 2s infinite ease;
  animation: stripe-loading 2s infinite ease;
}

@keyframes stripe-loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  #payment-form {
    width: 80vw;
    min-width: initial;
  }
}